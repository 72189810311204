<script>
import MetisMenu from "metismenujs/dist/metismenujs";

export default {
  data() {
    return {
      user: {
        permissions: {}
      }
    }
  },
  components: {},
  mounted: function() {
    this.$store.dispatch('USER_DATA').then((res) => {
      this.user = res;
    })
    
    document.body.setAttribute("data-sidebar", "dark");
    document.body.setAttribute("data-topbar", "light");
    // eslint-disable-next-line no-unused-vars
    var menuRef = new MetisMenu("#side-menu");
    var links = document.getElementsByClassName("side-nav-link-ref");
    var matchingMenuItem = null;
    for (var i = 0; i < links.length; i++) {
      if (window.location.pathname === links[i].pathname) {
        matchingMenuItem = links[i];
        break;
      }
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");
      var parent = matchingMenuItem.parentElement;

      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add("mm-active");
        const parent2 = parent.parentElement.closest("ul");
        if (parent2 && parent2.id !== "side-menu") {
          parent2.classList.add("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add("mm-active");
            var childAnchor = parent3.querySelector(".has-arrow");
            var childDropdown = parent3.querySelector(".has-dropdown");
            if (childAnchor) childAnchor.classList.add("mm-active");
            if (childDropdown) childDropdown.classList.add("mm-active");

            const parent4 = parent3.parentElement;
            if (parent4) parent4.classList.add("mm-show");
            const parent5 = parent4.parentElement;
            if (parent5) parent5.classList.add("mm-active");
          }
        }
      }
    }
  },
  methods: {
    lightSidebar() {
      document.body.setAttribute("data-topbar", "dark");
      document.body.removeAttribute("data-sidebar");
      document.body.removeAttribute("data-layout-size", "boxed");
      document.body.removeAttribute("data-sidebar-size", "small");
      document.body.classList.remove("vertical-collpsed");
    },
    compactSidebar() {
      document.body.setAttribute("data-sidebar-size", "small");
      document.body.setAttribute("data-sidebar", "dark");
      document.body.removeAttribute("data-layout-size", "boxed");
      document.body.classList.remove("vertical-collpsed");
      document.body.removeAttribute("data-topbar", "dark");
    },
    iconSidebar() {
      document.body.setAttribute("data-keep-enlarged", "true");
      document.body.classList.add("vertical-collpsed");
      document.body.setAttribute("data-sidebar", "dark");
      document.body.removeAttribute("data-topbar", "dark");
      document.body.removeAttribute("data-layout-size", "boxed");
    },
    boxedLayout() {
      document.body.setAttribute("data-keep-enlarged", "true");
      document.body.classList.add("vertical-collpsed");
      document.body.setAttribute("data-layout-size", "boxed");
      document.body.removeAttribute("data-sidebar", "colored");
      document.body.setAttribute("data-sidebar", "dark");
      document.body.removeAttribute("data-topbar", "dark");
    },
    coloredSidebar() {
      document.body.setAttribute("data-sidebar", "colored");
      document.body.removeAttribute("data-layout-size", "boxed");
      document.body.removeAttribute("data-sidebar-size", "small");
      document.body.classList.remove("vertical-collpsed");
    }
  }
};
</script>

<template>
  <div id="sidebar-menu">
    <ul id="side-menu" class="metismenu list-unstyled">
      <li class="menu-title">Menu</li>

      <li v-show="user.permissions.users">
        <router-link tag="a" to="/users" class="side-nav-link-ref">
          <i class="bx bx-user"></i>
          <span>Usuários/Clientes</span>
        </router-link>
      </li>

      <li v-show="user.permissions.notifications">
        <router-link tag="a" to="/notifications" class="side-nav-link-ref">
          <i class="bx bx-message-square-dots"></i>
          <span>Notificações</span>
        </router-link>
      </li>

      <li v-show="user.permissions.banners">
        <router-link tag="a" to="/banners" class="side-nav-link-ref">
          <i class="bx bx-image"></i>
          <span>Banners</span>
        </router-link>
      </li>

      <!-- <li v-show="user.permissions.images">
        <router-link tag="a" to="/product-image" class="side-nav-link-ref">
          <i class="bx bx-image-alt"></i>
          <span>Fotos de Produtos</span>
        </router-link>
      </li>

       <li v-show="user.permissions.paymentRules">
        <router-link tag="a" to="/payment-settings" class="side-nav-link-ref">
          <i class="bx bx-money"></i>
          <span>Regras de Pagamento</span>
        </router-link>
      </li>

      <li v-show="user.permissions.campaigns">
        <a href="javascript: void(0);" class="has-arrow">
          <i class="bx bx-shopping-bag"></i>
          <span>Campanhas</span>
        </a>
        <ul class="sub-menu" aria-expanded="false">
          <li>
            <router-link tag="a" to="/campaigns/delivery" class="side-nav-link-ref">Frete Grátis</router-link>
          </li>
          <li>
            <router-link tag="a" to="/campaigns/department" class="side-nav-link-ref">Desconto por Grupo</router-link>
          </li>
          <li>
            <router-link tag="a" to="/campaigns/product" class="side-nav-link-ref">Desconto por Produto</router-link>
          </li>
          <li>
            <router-link tag="a" to="/campaigns/coupons" class="side-nav-link-ref">Cupons de Desconto</router-link>
          </li>
        </ul>
      </li>

      <li v-show="user.permissions.permissions">
        <router-link tag="a" to="/permissions" class="side-nav-link-ref">
          <i class="bx bx-key"></i>
          <span>Acessos e Permissões</span>
        </router-link>
      </li>

      <li v-show="user.permissions.settings">
        <router-link tag="a" to="/settings" class="side-nav-link-ref">
          <i class="bx bx-cog"></i>
          <span>Configurações</span>
        </router-link>
      </li> -->
    </ul>
  </div>
</template>
